@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'OpenSans', -apple-system, BlinkMacSystemFont, 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #13211b;
}

@font-face {
  font-family: 'Sovana';
  src: url('./fonts/Sovana-Regular.otf') format('opentype'),
       url('./fonts/sovana-regular.ttf') format('truetype');
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Sovana', 'serif'
}

.question-selector:has(input:checked) {
  background: #45552C;
  color: white;
}

.email { width: 100% !important; }

.mc__form-container {
  width: 80%;
  max-width: 500px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid black;
  padding: 0;
}


